import store from "../state/store";
import Swal from "sweetalert2";

const currentBranch = async (projectName) => {
  console.log("project name: ", projectName);
  const api = store.getters.client;
  // try {
  const { data } = await api.get(
    `/orguser/branch/get-current-branch?repoName=${projectName}`
  );
  console.log("branch name:", data);
  return data;
  // } catch (error) {
  //   console.error(error);
  // }
};

// {
//   "repoName": "TestingOct12",
//   "baseBranchName": "master-2",
//   "newBranchName": "master-test-1",
//   "clone": true
// }
const createNewBranch = async (body, branches) => {
  console.log("create new branch: ", body, branches);
  const api = store.getters.client;

  // Now send this data to the API
  try {
    const { data } = await api.post(`/orguser/branch/create`, body);
    console.log("new branch create:", data);
    return {
      type: "success",
      data,
    };
  } catch (error) {
    console.error(error);
    return { type: "error", error };
  }
};

const discardOrCommit = async (body) => {
  console.log("discardOrCommit : ", body);
  const api = store.getters.client;
  try {
    const { data } = await api.patch(`/orguser/branch/discard-or-commit`, body);

    //  body = { "action": "commit",   // or 'discard' "branchToCheckout": "master-3", "commitMessage": "Removing some files - Testing 2", // Not needed when discard "repoName": "TestingOct12"}
    console.log("res discardOrCommit:", data);
    return data;
  } catch (error) {
    console.error(error);
  }
};

// {
//   repoName: this.projectName,
//   branchName: this.brachName,
// },
const checkout = async (body, VueInstance) => {
  const api = store.getters.client;
  let loadingInstance;
  try {
    loadingInstance = VueInstance.$loading.show({
      loader: "dots",
    }); // Show loading
    const { data } = await api.put(`/orguser/branch/checkout`, body);
    console.log("checkout service:", data);
    if (loadingInstance) {
      loadingInstance.hide(); // Hide loading
    }
    return data;
  } catch (error) {
    console.log(error);
    if (loadingInstance) {
      loadingInstance.hide(); // Hide loading
    }
    if (error.response.data.data === "discard-or-commit") {
      // Show Swal.fire dialog and handle the result
      const result = await Swal.fire({
        title: "Warning",
        text: "You have unsaved changes. Do you want to discard them or commit?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#556ee6",
        confirmButtonText: "Discard",
        cancelButtonColor: "#f46a6a",
        cancelButtonText: "Commit",
      });

      console.log(result);
      loadingInstance = VueInstance.$loading.show({
        loader: "dots",
      }); // Show loading

      // If the user clicked the "Discard" button
      if (result.isConfirmed) {
        console.log("User clicked Discard");
        return await discardOrCommit({
          action: "discard",
          branchToCheckout: body.branchName,
          repoName: body.repoName,
        });
      }
      // If the user clicked the "Commit" button
      else if (result.dismiss === Swal.DismissReason.cancel) {
        console.log("User clicked Commit");
        return await discardOrCommit({
          action: "commit",
          branchToCheckout: body.branchName,
          commitMessage: `Committing changes while checkout ${body.repoName} : ${body.branchName}`, // Add an appropriate commit message
          repoName: body.repoName,
        });
      }
      if (loadingInstance) {
        loadingInstance.hide(); // Hide loading
      }
      return false; // If neither option was chosen
    }
    console.error(error.response.data.data);
  } finally {
    if (loadingInstance) {
      loadingInstance.hide(); // Hide loading
    }
  }
};

const githubService = {
  currentBranch,
  createNewBranch,
  discardOrCommit,
  checkout,
};
export default githubService;
