<template>
  <!-- Card component for displaying a list of projects -->
  <div class="card">
    <div
      class="custom-notifications d-flex justify-content-between align-items-center flex-wrap"
    >
      <div class="custom-title mb-0">Projects</div>
      <button
        class="btn text-primary view-details-btn btn-sm m-0"
        @click="viewMore"
      >
        <span class="mdi mdi-eye-outline mr-2"></span> View more
      </button>
    </div>
    <div data-simplebar class="card-body border-top py-2 mb-3">
      <div class="table-responsive table-size">
        <table class="table table-hover font-size-14 mb-0">
          <tbody>
            <!-- Skeleton loading table when 'showSpinner' is true -->
            <b-skeleton-table
              v-if="showSpinner"
              :rows="5"
              :columns="1"
              :table-props="{ bordered: true, striped: true }"
            ></b-skeleton-table>
            <!-- Render project list when available and not loading -->
            <template
              v-else-if="projectList && projectList.length > 0 && !showSpinner"
            >
              <tr v-for="(item, index) in displayedProjects" :key="item.id">
                <td>
                  <div class="d-flex align-items-center">
                    <div class="mr-3">
                      <span class="avatar rounded-circle">
                        <i class="ri-folder-fill ri-lg text-primary"></i>
                      </span>
                    </div>
                    <h5 class="font-size-13 mb-1 text-uppercase">
                      {{ projectList[index].projectName }}
                    </h5>
                  </div>
                </td>
              </tr>
            </template>
            <!-- Display a message when no projects are available -->
            <template v-else>
              <tr>
                <td colspan="2" class="text-center font-size-14 custom-margin">
                  <div class="custom-container">
                    <img
                      class="custom-image"
                      src="../../../../assets/placeholder.png"
                    />
                    <div class="custom-text">No Projects added</div>
                  </div>
                </td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";
export default {
  props: {
    items: Object,
  },
  data() {
    return {
      userId: this.$store.state.Auth.userId,
      projectList: null, // List of user projects
      showSpinner: true, // Show a loading spinner initially
      displayedProjects: [],
    };
  },
  mounted() {
    if (this.$store.state.Auth.projectsList.length) {
      this.showSpinner = false;
      this.projectList = this.$store.state.Auth.projectsList;
      this.displayedProjects = this.projectList.slice(0, 8);
    } else {
      this.getProjectData(); // Fetch user projects data
    }
  },
  methods: {
    messageToast(messageToastTitle, messageToastVariant, messageToastContent) {
      this.$bvToast.toast(messageToastContent, {
        title: messageToastTitle,
        variant: messageToastVariant,
        solid: true,
      });
    },
    ...mapActions({
      get: "userProjectDetails",
    }),
    viewMore() {
      this.$router.push({
        name: "DocManager",
      });
    },
    // Fetch user projects data from the server
    getProjectData() {
      this.$store.getters.client
        .get(`/projectuser/byuserid?userId=${this.userId}`)
        .then((response) => {
          if (response.data) {
            this.showSpinner = false;
            this.projectList = response.data;
            this.displayedProjects = this.projectList.slice(0, 8);
            this.$store.commit("setProjectsList", response.data);
          } else {
            this.messageToast(
              "Invalid request",
              "danger",
              "No data received from the server"
            );
            this.showSpinner = false;
          }
        })
        .catch((err) => {
          this.messageToast(
            "Invalid request",
            "danger",
            err.response ? err.response.data.message : "An error occurred"
          );
          this.showSpinner = false;
        });
    },
    // Redirect to the project page
    async redirectProject() {
      this.$router.push({
        path: `/docmanager`,
      });
      // location.reload();
    },
  },
};
</script>
<style scoped>
.custom-notifications {
  padding: 14px;
  gap: 24px;
}
.custom-title {
  font-size: 18px;
  font-weight: 500;
  line-height: 1.5;
  letter-spacing: 0.5px;
  text-align: left;
  color: rgba(23, 35, 61, 1);
}
.card {
  height: 50rem;
}
.custom-margin {
  margin-top: 10px;
}
.custom-container {
  position: relative;
  display: inline-block;
}
.view-details-btn {
  background-color: rgba(113, 165, 203, 0.2);
}
.custom-image {
  width: min(90%, 250px);
}
.custom-text {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  color: #666;
  padding-top: 0px;
  font-size: 16px;
}
.btn {
  white-space: nowrap;
}

@media (max-width: 991px) {
  .card {
    max-height: 500px;
  }
}
</style>
