import store from "../state/store";
const downloadSourceFiles = async ({ repoName, commitSHA = "" }) => {
  console.log("in downloadSourceFiles: ", repoName, commitSHA);
  const api = store.getters.client;

  await api
    .get(
      `/orguser/docpublish/download-output?repoName=${repoName}&commitSHA=${commitSHA}`,
      {
        responseType: "blob",
      }
    )
    .then((response) => {
      console.log("download res: ", response);
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", repoName + ".zip");
      document.body.appendChild(link);
      link.click();
    })
    .catch((error) => {
      throw error;
    });
};

export default downloadSourceFiles;
