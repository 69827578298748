<template>
  <b-modal id="changeBranch-bv-modal" hide-footer>
    <template #modal-title>
      Create new branch {{ clone ? "(Clone)" : "(Empty)" }}</template
    >

    <div>
      <label>Select Base Branch<span class="text-danger">*</span></label>

      <!-- Multiselect for project selection -->
      <multiselect
        v-model="baseBranchName"
        :options="branches"
        placeholder="Choose a branch"
        :max-height="150"
      >
      </multiselect>
      <div class="my-3">
        <label>New Branch Name<span class="text-danger">*</span></label>
        <input
          class="form-control"
          id="newBranchName"
          v-model="newBranchName"
        />
      </div>
      <div class="text-center">
        <!-- @click="createNewBranchHandler" -->
        <button
          class="btn btn-primary"
          :disabled="!baseBranchName || !newBranchName"
          @click="handleBranchChange"
        >
          Create new branch
        </button>
      </div>
    </div>
  </b-modal>
</template>

<script>
import Multiselect from "vue-multiselect";
export default {
  name: "changeBranch",

  components: {
    Multiselect,
  },

  props: {
    branches: {
      type: Array,
      default: () => {
        return [];
      },
    },
    createNewBranchHandler: {
      type: Function,
    },
    clone: {
      type: Boolean,
    },
  },
  data() {
    return {
      newBranchName: "",
      baseBranchName: "",
    };
  },
  methods: {
    async handleBranchChange() {
      await this.createNewBranchHandler({
        baseBranchName: this.baseBranchName,
        newBranchName: this.newBranchName,
      });
      console.log("after complete create branch");
      this.newBranchName = "";
      this.baseBranchName = "";
    },
  },
};
</script>

<style scoped></style>
