<template>
  <div>
    <PageHeader :title="title" :items="items" />
    <!-- Main Card -->
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row mt-2 d-flex justify-content-between mx-3">
              <div id="tickets-table_length" class="dataTables_length">
                <label class="d-inline-flex align-items-center">
                  Show&nbsp;
                  <b-form-select
                    v-model="perPage"
                    size="sm"
                    :options="pageOptions"
                  ></b-form-select
                  >&nbsp;entries
                </label>
              </div>

              <div
                class="d-flex align-items-center justify-content-between mb-3"
              >
                <div class="search-box mr-3">
                  <div class="position-relative">
                    <input
                      type="text"
                      class="form-control text-primary rounded custom-input-box"
                      placeholder="Search"
                      id="myInput"
                      v-model="filter"
                    />
                    <i class="mdi mdi-magnify search-icon"></i>
                  </div>
                </div>
              </div>
            </div>

            <!-- Data Table -->
            <div class="table-responsive mb-0">
              <b-table
                :items="activityData"
                :fields="colfields"
                responsive="sm"
                :per-page="perPage"
                :current-page="currentPage"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :filter="filter"
                :filter-included-fields="filterOn"
                @filtered="onFiltered"
                :filter-function="filterTable"
                ref="adminTable"
                show-empty
              >
                <!-- Custom cell rendering for createdAt -->
                <template #cell(createdAt)="row">
                  {{ moment(row.item.createdAt).format("YYYY-MM-DD h:mm A") }}
                </template>
                <!-- Custom cell rendering for userRole -->
                <template #cell(userRole)="row">
                  {{ formatUserRoles(row.item.userRole) }}
                </template>
              </b-table>
            </div>
            <!-- Pagination and Total Rows Count -->
            <div class="col-12 border-top pt-3">
              <div class="d-flex justify-content-end">
                <b-pagination
                  v-model="currentPage"
                  :total-rows="filteredData.length"
                  :per-page="perPage"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import moment from "moment";
import _ from "lodash";
import { eventBus } from "../../../main";
export default {
  data() {
    return {
      activityData: [],
      filteredData: [],
      userId: this.$store.state.Auth.userId,
      userRoleMap: {
        DocManager: "DocManager",
        DocPublisher: "DocPublisher",
        DocEditor: "DocEditor",
        DocMigration: "DocMigration",
      },
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "",
      sortDesc: true,
      colfields: this.generateTableColumns(),
      title: "User Role ",
      items: [
        {
          text: "Dashboard",
          href: `/dashboard`,
        },
        {
          text: "User Role",
          active: true,
        },
      ],
    };
  },
  computed: {
    rows() {
      return this.activityData.length;
    },
    _() {
      return _;
    },
  },
  created() {
    eventBus.$emit("update-sidebar", "menuitems.dashboard.text");
    if (this.$store.state.Auth.projectsData.length) {
      this.activityData = this.$store.state.Auth.projectsData;
    } else {
      this.getProjectsWithUserRole();
    }
  },
  watch: {
    perPage: {
      handler() {
        this.currentPage = 1;
      },
    },
    activityData: {
      handler() {
        this.filteredData = this.activityData;
      },
    },
    filter: {
      handler() {
        this.filteredData = this.activityData?.filter((data) =>
          data.projectName.toUpperCase().includes(this.filter?.toUpperCase())
        );
      },
    },
  },
  methods: {
    moment,
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    generateTableColumns() {
      return [
        {
          key: "projectName",
          label: "Project Name",
          sortable: false,
        },
        {
          key: "userRole",
          label: "User role",
          sortable: false,
          formatter: (value, key, item) => {
            return this.formatUserRoles(item.userRole);
          },
        },
        {
          key: "createdAt",
          label: "Created At",
          sortable: false,
        },
      ];
    },
    // This method is use to generate error or success message
    messageToast(messageToastTitle, messageToastVariant, messageToastContent) {
      this.$bvToast.toast(messageToastContent, {
        title: messageToastTitle,
        variant: messageToastVariant,
        solid: true,
      });
    },
    formatUserRoles(userRoles) {
      return userRoles.map((role) => this.userRoleMap[role]).join(", ");
    },
    getProjectsWithUserRole() {
      this.$store.getters.client
        .get(`/projectuser/byuserid?userId=${this.userId}`)
        .then((response) => {
          if (response.data) {
            this.activityData = response.data;
            this.$store.commit("setProjectsData", response.data);
          } else {
            this.messageToast(
              "Invalid request",
              "danger",
              "No data received from the server"
            );
          }
        })
        .catch((err) => {
          this.messageToast(
            "Invalid request",
            "danger",
            err.response ? err.response.data.message : "An error occurred"
          );
          this.showSpinner = false;
        });
    },
    filterTable(row, filter) {
      const filterUpperCase = filter.toUpperCase();
      const hasNameMatch = row.projectName
        .toUpperCase()
        .includes(filterUpperCase);
      return hasNameMatch;
    },
  },
};
</script>
<style>
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .row-count-cust-cls {
    margin-bottom: 10px;
  }
  .row-pagination-cust-cls {
    margin-top: 20px;
  }
}
/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .row-count-cust-cls ul {
    margin-bottom: 10px;
    text-align: left !important;
  }
}
/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
}
/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
}
/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
}
</style>
